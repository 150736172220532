import React from 'react';
import './index.scss';

const SectionTop = () => (
  <div>
    <div className="section-top__people-top">
      <svg
        width="25"
        height="26"
        viewBox="0 0 25 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.503 1.51682C12.1614 -2.86907 1.03065 2.82074 0.0833498 11.3554C-0.982363 19.8901 8.37223 27.9507 16.6611 25.5799C24.8316 23.2092 29.0944 7.32516 19.503 1.51682Z"
          fill="#E4F2F2"
        />
      </svg>
      <div className="face-1">
        <picture>
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-1.2x.webp"
            type="image/webp"
          />
          <source
            srcSet="/img/img_face-1.webp"
            type="image/webp"
          />
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-1.2x.png"
            type="image/png"
          />
          <img
            src="/img/img_face-1.png"
            alt="Experienced mentors"
          />
        </picture>
      </div>
      <div className="face-2">
        <picture>
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-2.2x.webp"
            type="image/webp"
          />
          <source
            srcSet="/img/img_face-2.webp"
            type="image/webp"
          />
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-1.2x.webp"
            type="image/webp"
          />
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-2.2x.png"
            type="image/png"
          />
          <img
            src="/img/img_face-2.png"
            alt="Experienced mentors"
          />
        </picture>
      </div>
      <div className="face-3">
        <picture>
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-3.2x.webp"
            type="image/webp"
          />
          <source
            srcSet="/img/img_face-3.webp"
            type="image/webp"
          />
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-3.2x.png"
            type="image/png"
          />
          <img
            src="/img/img_face-3.png"
            alt="Experienced mentors"
          />
        </picture>
      </div>
      <div className="face-4">
        <picture>
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-4.2x.webp"
            type="image/webp"
          />
          <source
            srcSet="/img/img_face-4.webp"
            type="image/webp"
          />
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-4.2x.png"
            type="image/png"
          />
          <img
            src="/img/img_face-4.png"
            alt="Experienced mentors"
          />
        </picture>
      </div>
      <div className="face-5">
        <picture>
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-5.2x.webp"
            type="image/webp"
          />
          <source
            srcSet="/img/img_face-5.webp"
            type="image/webp"
          />
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-5.2x.png"
            type="image/png"
          />
          <img
            src="/img/img_face-5.png"
            alt="Experienced mentors"
          />
        </picture>
      </div>
      <div className="face-6">
        <picture>
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-6.2x.webp"
            type="image/webp"
          />
          <source
            srcSet="/img/img_face-6.webp"
            type="image/webp"
          />
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-6.2x.png"
            type="image/png"
          />
          <img
            src="/img/img_face-6.png"
            alt="Experienced mentors"
          />
        </picture>
      </div>
      <div className="face-7">
        <picture>
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-7.2x.webp"
            type="image/webp"
          />
          <source
            srcSet="/img/img_face-7.webp"
            type="image/webp"
          />
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-7.2x.png"
            type="image/png"
          />
          <img
            src="/img/img_face-7.png"
            alt="Experienced mentors"
          />
        </picture>
      </div>
      <div className="face-8">
        <picture>
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-8.2x.webp"
            type="image/webp"
          />
          <source
            srcSet="/img/img_face-8.webp"
            type="image/webp"
          />
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-8.2x.png"
            type="image/png"
          />
          <img
            src="/img/img_face-8.png"
            alt="Experienced mentors"
          />
        </picture>
      </div>
      <div className="face-9">
        <picture>
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-9.2x.webp"
            type="image/webp"
          />
          <source
            srcSet="/img/img_face-9.webp"
            type="image/webp"
          />
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-9.2x.png"
            type="image/png"
          />
          <img
            src="/img/img_face-9.png"
            alt="Experienced mentors"
          />
        </picture>
      </div>
      <div className="face-10">
        <picture>
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-10.2x.webp"
            type="image/webp"
          />
          <source
            srcSet="/img/img_face-10.webp"
            type="image/webp"
          />
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-10.2x.png"
            type="image/png"
          />
          <img
            src="/img/img_face-10.png"
            alt="Experienced mentors"
          />
        </picture>
      </div>
    </div>
    <div className="section-top__content">
      <div className="section-top__content__title">
        快速简单的获取高质量的<br />定制写作帮助
      </div>
      <h1 className="section-top__content__description">
        我们为您提供专业的定制文章，学期论文，研究报告，毕业论文，学位论文，读后感，书评，演讲稿，作业，商务写作，网站内容定制等其他服务。
      </h1>
      <a rel="nofollow" href="https://www.customwritings.com/dashboard/orders/create/" className="btn">
        在线下订单
      </a>
    </div>
    <div className="section-top__people-bottom">
      <svg
        width="45"
        height="43"
        viewBox="0 0 45 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M38.6259 27.9684C33.7531 38.0375 16.096 39.5349 8.39932 29.6049C1.32893 20.5449 6.45491 6.32042 21.5345 7.21712C40.2541 8.39976 41.3077 22.4454 38.6259 27.9684Z"
          fill="#D6F0F0"
        />
      </svg>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.98236 11.3786C10.9932 10.7676 11.6456 9.67636 11.9846 8.47385C13.1842 3.60816 6.25774 0.265169 3.18379 4.2003C-0.0519377 8.34254 5.61526 14.2369 9.98236 11.3786Z"
          fill="#D6F0F0"
        />
      </svg>
      <div className="face-11">
        <picture>
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-11.2x.webp"
            type="image/webp"
          />
          <source
            srcSet="/img/img_face-11.webp"
            type="image/webp"
          />
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-11.2x.png"
            type="image/png"
          />
          <img
            src="/img/img_face-11.png"
            alt="Experienced mentors"
          />
        </picture>
      </div>
      <div className="face-12">
        <picture>
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-12.2x.webp"
            type="image/webp"
          />
          <source
            srcSet="/img/img_face-12.webp"
            type="image/webp"
          />
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-12.2x.png"
            type="image/png"
          />
          <img
            src="/img/img_face-12.png"
            alt="Experienced mentors"
          />
        </picture>
      </div>
      <div className="face-13">
        <picture>
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-13.2x.webp"
            type="image/webp"
          />
          <source
            srcSet="/img/img_face-13.webp"
            type="image/webp"
          />
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-13.2x.png"
            type="image/png"
          />
          <img
            src="/img/img_face-13.png"
            alt="Experienced mentors"
          />
        </picture>
      </div>
      <div className="face-14">
        <picture>
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-14.2x.webp"
            type="image/webp"
          />
          <source
            srcSet="/img/img_face-14.webp"
            type="image/webp"
          />
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-14.2x.png"
            type="image/png"
          />
          <img
            src="/img/img_face-14.png"
            alt="Experienced mentors"
          />
        </picture>
      </div>
      <div className="face-15">
        <picture>
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-15.2x.webp"
            type="image/webp"
          />
          <source
            srcSet="/img/img_face-15.webp"
            type="image/webp"
          />
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-15.2x.png"
            type="image/png"
          />
          <img
            src="/img/img_face-15.png"
            alt="Experienced mentors"
          />
        </picture>
      </div>
      <div className="face-16">
        <picture>
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-16.2x.webp"
            type="image/webp"
          />
          <source
            srcSet="/img/img_face-16.webp"
            type="image/webp"
          />
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-16.2x.png"
            type="image/png"
          />
          <img
            src="/img/img_face-16.png"
            alt="Experienced mentors"
          />
        </picture>
      </div>
      <div className="face-17">
        <picture>
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-17.2x.webp"
            type="image/webp"
          />
          <source
            srcSet="/img/img_face-17.webp"
            type="image/webp"
          />
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-17.2x.png"
            type="image/png"
          />
          <img
            src="/img/img_face-17.png"
            alt="Experienced mentors"
          />
        </picture>
      </div>
      <div className="face-18">
        <picture>
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-18.2x.webp"
            type="image/webp"
          />
          <source
            srcSet="/img/img_face-18.webp"
            type="image/webp"
          />
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-18.2x.png"
            type="image/png"
          />
          <img
            src="/img/img_face-18.png"
            alt="Experienced mentors"
          />
        </picture>
      </div>
      <div className="face-19">
        <picture>
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-19.2x.webp"
            type="image/webp"
          />
          <source
            srcSet="/img/img_face-19.webp"
            type="image/webp"
          />
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-19.2x.png"
            type="image/png"
          />
          <img
            src="/img/img_face-19.png"
            alt="Experienced mentors"
          />
        </picture>
      </div>
      <div className="face-20">
        <picture>
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-20.2x.webp"
            type="image/webp"
          />
          <source
            srcSet="/img/img_face-20.webp"
            type="image/webp"
          />
          <source
            media="(min-width: 1280px)"
            srcSet="/img/img_face-20.2x.png"
            type="image/png"
          />
          <img
            src="/img/img_face-20.png"
            alt="Experienced mentors"
          />
        </picture>
      </div>
    </div>
  </div>
);

export default SectionTop;
