import React from 'react';
import Slider from 'react-slick';
import './_index.scss';

const settings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  centerMode: true,
  variableWidth: true,
  autoplay: true,
  autoplaySpeed: 10000,
};

const Statistics = () => (
  <div className="stats-list">
    <Slider {...settings}>
      <div className="stats-list__item slide1"><span className="stats-list__item__value">97.10%</span>订单交付的截止日期</div>
      <div className="stats-list__item slide2"><span className="stats-list__item__value">8.5{' '}<span>/ 10</span></span>客户对写作质量的评价</div>
      <div className="stats-list__item slide3"><span className="stats-list__item__value">10+</span>年度学术论文</div>
      <div className="stats-list__item slide4"><span className="stats-list__item__value">508</span>在线作家</div>
    </Slider>
  </div>
);

export default Statistics;
