// @todo enable next eslint rules
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
/* eslint-disable no-new */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import classNames from 'classnames';
import Spinner from '../Spinner';

import './_Calculator.scss';


class Calculator extends Component {
  state = {
    calcLoaded: false,
  };

  payInTwoCheckboxRef = React.createRef(null);

  componentDidMount() {
    const _this = this;
    const payInTwoCheckbox = this.payInTwoCheckboxRef.current;

    if (payInTwoCheckbox) payInTwoCheckbox.checked = payInTwoCheckbox.defaultChecked;

    function getScript(url, callback) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.onload = callback;
      document.querySelector('[data-calc-mini]').appendChild(script);
    }

    getScript('/assets/js/calc.js', () => {
      _this.getCalc();
    });
  }

  getCalc = () => {
    new Calc({
      ID: '0',
      calculatorType: 'DefaultCalculator',
      format: 'json',
      callback: (i) => {
        this.setState({
          calcLoaded: !i.isLoading,
        });
      },
      options: {
        deafault: {
          deadlinesDefault: [66279, 66289, 66299, 66309, 66319],
        },
        currency: {
          availableCurrency: ['CNY', 'GBP', 'USD'],
          decimalCurrency: true,
          defaultCurrency: 'CNY',
        },
      },
    });
  };

  render() {
    const { calcLoaded } = this.state;

    return (
      <div className="CalcFull" data-calc-mini>
        <form
          name="uvocalculator"
          method="get"
          action="/"
          className={classNames(
            'Calc',
            !calcLoaded && 'loading',
            calcLoaded && 'loaded',
          )}
          data-calculator-form
        >
          <label data-currency-wrap className="select Calc__currency-select">
            <span>货币</span>
            <select data-currency />
          </label>
          <select name="topcatId" style={{ display: 'none' }} data-discipline-select />
          <div className="Calc__wrapper">
            <div className="Calc__cell Calc__cell--type-of-paper">
              <label htmlFor="calc-type-of-paper" className="Calc__label select">
                <span>需要的文章类型</span>
                <select id="calc-type-of-paper" name="paperTypeId" data-academic-level-select />
              </label>
            </div>
            <div className="Calc__cell Calc__cell--academic-level">
              <label htmlFor="calc-academic-level" className="Calc__label select">
                <span>学术水平</span>
                <select id="calc-academic-level" data-academic-level-select-mobile />
              </label>
            </div>
            <div className="Calc__cell Calc__cell--academic-level-select">
              <div className="Calc__select Calc__select--no-gradient select">
                <div className="Calc--hidden" data-academic-level-control-upgrade="" />
                <div className="uvocalc--academic_level_content" data-tariff-control-upgrade="" />
              </div>
            </div>
            <div className="Calc__cell--academic-level-mobile">
              <div className="Calc__academic-level--mobile" data-tariff-control-mobile="" />
            </div>
            <div className="Calc__tip" style={{ display: 'none' }} data-tip-discount>您将获得一对一服务和优惠。</div>
            <div className="Calc__tip" data-notice />
            <div className="Calc__cell Calc__cell--pages">
              <label className="Calc__label">页数</label>
              <div className="Calc__pages">
                <input className="Calc__pages__input" type="text" name="pages" data-pages-input value="2" maxLength="5" onChange={() => {}} />
                <span className="Calc__label-item-words" data-words-qty="">550 words</span>
                <div className="Calc__pages__buttons">
                  <button type="button" data-minus-button aria-label="decrease" />
                  <button type="button" data-plus-button aria-label="increase" />
                </div>
              </div>
            </div>
            <div className="Calc__footer">
              <div className="pay-parts">
                <label className="pay-parts__checkbox">
                  <input ref={this.payInTwoCheckboxRef} className="pay-parts__checkbox__input" type="checkbox" data-first-installment-input />
                  <span className="pay-parts__checkbox__checkmark" />
                </label>
                <span>选择2次分期服务!</span>
                <div className="pay-parts__info" />
                <div className="pay-parts__inner">
                  <p>下单时您只需支付价格的 50%，等我们完成您的论文并交付给您后，再提交剩余款项。
                    我们的分期服会在总价格额外添加 5%的服务费用。
                  </p>
                </div>
              </div>
              <div className="Calc__prices">
                <div className="Calc__first-installment">第一分期：{' '}
                  <span className="Calc__first-installment-value" data-first-installment>$0</span>
                </div>
                <div className="Calc__total">总价：<span className="Calc__total-value" data-total-price>$0</span></div>
              </div>
              <button className="Calc__submit btn btn--red--white" type="submit" data-submit-button>继续下单</button>
              <div className="Calc__tip Calc__tip--deadline">
                <div className="Calc__tip__deadline">
                  下订单只需几分钟。<br />
                  保证服务质量和100%保密性。
                </div>
              </div>
            </div>
          </div>
        </form>
        {!calcLoaded && <Spinner />}
      </div>
    );
  }
}

export default Calculator;
