import React from 'react';
import PropTypes from 'prop-types';

import './_index.scss';

const Banner = ({ children }) => (
  <div className="section-banner__content img_CTA">
    {children}
  </div>
);

Banner.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Banner;
