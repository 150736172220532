import React from 'react';
import PropTypes from 'prop-types';

import './_Features.scss';


const Features = ({ children }) => (
  <div className="features">
    {children}
  </div>
);

Features.propTypes = {
  children: PropTypes.node,
};

Features.defaultProps = {
  children: undefined,
};

export default Features;
