import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import Testimonial from './Testimonial';

import './_Testimonials.scss';


const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToScroll: 3,
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        draggable: true,
      },
    },
  ],
};

const Testimonials = ({ testimonials }) => (
  <div className="testimonials">
    <div className="testimonials-slider">
      <Slider {...settings}>
        {
          // eslint-disable-next-line max-len
          [].slice.call(testimonials).map(testimonial => <Testimonial key={testimonial.id} {...testimonial} />)
        }
      </Slider>
    </div>
  </div>
);

Testimonials.propTypes = {
  testimonials: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
};

export default Testimonials;
