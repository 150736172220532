import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


const FeaturesItem = ({ heading, text, icon }) => (
  <div className={
    classNames(
      'features__item plagiarism-free',
      icon,
    )}
  >
    <h3 className="features__item__title">{heading}</h3>
    <p className="features__item__text">{text}</p>
  </div>
);

FeaturesItem.propTypes = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default FeaturesItem;
