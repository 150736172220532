// @todo enable next eslint rules
/* eslint-disable max-len */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import Helmet from 'react-helmet';
import staticData from './index-data.json';
import Layout from '../components/Layout';
import { Banner, BannerButtons } from '../components/Banner';
import { Section, SectionHeading } from '../components/Section';
import Samples from '../components/Samples/Samples';
import { Features, FeaturesItem } from '../components/Features';
import Statistics from '../components/Statistics';
import WeDoBlock from '../components/WeDoBlock';
import SectionTop from '../components/SectionTop';
import Testimonials from '../components/Testimonials/Testimonials';
import { Calculator, CalculatorAside } from '../components/Calculator';
import LazyImage from '../components/LazyImage';
import './index.scss';

import imgCta from '../img/img_CTA.svg';
import imgCta3 from '../img/img_cta-3.svg';
import imgSecure from '../img/img_secure.svg';

const IndexPage = () => (
  <Layout>
    <Helmet>
      <title>论文代写服务 | Cn.CustomWritings.com</title>
      <meta
        name="description"
        content="我們的網站网站提供专业的代写服务，其中包括文章，学期论文，研究报告，毕业论文，书评，演讲稿，作业，商务写作，网站内容定制等其他服务。所有文章都是由经验丰富的作家原创完成的。"
      />
      <link rel="alternate" href="https://www.customwritings.com" hrefLang="x-default" />
      <link rel="alternate" href="https://www.customwritings.com" hrefLang="en-us" />
      <link rel="alternate" href="https://www.customwritings.com/ae" hrefLang="en-ae" />
      <link rel="alternate" href="https://ca.customwritings.com" hrefLang="en-ca" />
      <link rel="alternate" href="https://cn.customwritings.com" hrefLang="zh" />
      <link rel="canonical" href="https://cn.customwritings.com" />
    </Helmet>

    <Section className="section-top">
      <SectionTop />
    </Section>

    <Section className="section-features white-bg">
      <Features>
        <FeaturesItem
          heading="专业的作家"
          text="强大的作家团队经验丰富，可以完成任何类型的作业和学科。"
          icon="ico_superwriters"
        />
        <FeaturesItem
          heading="100%退款保证"
          text="如果对订单不满意，客户有权在完成订单的任何时候要求退款。"
          icon="ico_moneyback"
        />
        <FeaturesItem
          heading="原创文章"
          text="为了确保作家完成的订单是原创的，我们在向客户递交订单的时候会检测每个订单的抄袭率。"
          icon="ico_plagiarism-free"
        />
      </Features>
    </Section>

    <Section className="section-calculator section--bg-blue">
      <SectionHeading>计算订单价格</SectionHeading>
      <div className="calculator-wrapper">
        <Calculator />
        <CalculatorAside />
      </div>
    </Section>

    <Section className="section-statistics white-bg">
      <Statistics />
    </Section>

    <Section className="section-advantages section--bg-blue">
      <SectionHeading>选择我们服务的优势</SectionHeading>
      <Features>
        <FeaturesItem
          heading="高质量完成任何格式的写作"
          text="我们的作家会提供您需要的写作服务。MLA / APA / Harvard / Chicago / Turabian引用格式？没问题！这对作家来说等于小菜一碟。没有比我们的定制写作服务更适合您的了。"
          icon="ico_custom-formatting"
        />
        <FeaturesItem
          heading="全部文章都是原创的"
          text="所有的论文和文章都是由经验丰富的作家根据客户的要求和指示完成的，所以完全不用担心文章的抄袭率。我们的强大学术作家团队会帮您选择最适合的作家为您完成论文。"
          icon="ico_free-features"
        />
        <FeaturesItem
          heading="我们不会为您提供已完成的作品"
          text="不像其他网站，我们不会将以完成的作品卖给您。所有的文章都是原创的。之前完成的作品不会被存档。"
          icon="ico_quality"
        />
        <FeaturesItem
          heading="高质量的优秀作品"
          text="网站的目标是在市场设定高标准的定制写作服务，并为客户提供高质量的原创文章。我们公司提供的在线定制服务是以您的成功为目标的。"
          icon="ico_freerevisions"
        />
      </Features>
    </Section>

    <Section className="section-testimonials">
      <SectionHeading>客户的评价</SectionHeading>
      <Testimonials testimonials={staticData.testimonials} />
    </Section>

    <Section className="section-banner">
      <Banner>
        <h2 className="section-banner__heading">马上下单获得高质量定制文章</h2>
        <p className="section-banner__description">灵活，方便。24/7在线。</p>
        <div className="section-banner__img">
          <picture>
            <LazyImage
              src={imgCta}
            />
          </picture>
        </div>
        <BannerButtons>
          <a href="//www.customwritings.com/dashboard/orders/create/" className="btn" rel="nofollow">马上下单</a>
          <a href="//www.customwritings.com/dashboard/inquiry/" className="btn btn--black-blue" rel="nofollow">免费申请</a>
        </BannerButtons>
      </Banner>
    </Section>

    <Section className="section-wedo">
      <SectionHeading>我们尽最大努力确保客户的满意度</SectionHeading>
      <WeDoBlock />
    </Section>

    <Section className="section-banner section-banner--guarantee section--bg-blue">
      <Banner>
        <h2 className="section-banner__heading section-banner__heading--center">保证保密和真实性</h2>
        <ul className="list--check">
          <li>保证文章定制写作的真实性和客户所有信息的保密性。我们不会重新使用任何文章，也不会使用客户的个人信息。</li>
          <li>我们使用安全的交流工具。数据库的所有数据都已加密。我们不会保存明码的密码，只使用加密的。服务系统整体的正常使用时间为99.9%。</li>
        </ul>
        <div className="section-banner__img">
          <picture>
            <LazyImage src={imgSecure} />
          </picture>
        </div>
      </Banner>
    </Section>

    <Section className="section-samples">
      <SectionHeading>范文</SectionHeading>
      <div className="samples-slider-container">
        <svg xmlns="http://www.w3.org/2000/svg" width="92" height="112" fill="none"><path fill="#D6F0F0" d="M37.26 53.3c16.5 3.05 25.42 27.33 13.93 41.9-10.47 13.37-33.1 11.53-37.57-10.02C8.2 58.4 28.2 51.62 37.26 53.3zM85.07 5.32c-1.5-1.4-3.63-1.98-5.8-2-8.8.17-11.37 13-3.33 16.3 8.46 3.48 15.92-8.4 9.13-14.3z" /></svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="88" height="88" fill="none"><path fill="#ABDFDF" d="M43.72 9.8c16.82-4.16 41.2 6.82 36.98 25.82-3.8 17.4-30.93 33.94-44.8 15.1C18.8 27.2 34.5 12.06 43.73 9.8z" /><path fill="#BBE5E5" d="M10.77 86.37c2.32 0 4.52-1.17 6.3-2.84 6.96-6.94-1.26-19.58-10.4-16.1-9.64 3.66-6.12 19.3 4.1 18.94z" /></svg>
        <Samples samples={staticData.samples} />
      </div>
    </Section>

    <Section className="section-banner section-banner--bottom">
      <Banner>
        <h2 className="section-banner__heading">马上下单获得高质量定制文章</h2>
        <div className="section-banner__img">
          <picture>
            <LazyImage src={imgCta3} />
          </picture>
        </div>
        <BannerButtons>
          <a href="//www.customwritings.com/dashboard/orders/create/" className="btn" rel="nofollow">马上下单</a>
          <a href="//www.customwritings.com/dashboard/inquiry/" className="btn btn--black-blue" rel="nofollow">免费申请</a>
        </BannerButtons>
      </Banner>
    </Section>

    <Section className="section-seotext">
      <h2>定制写作服务并不代表作弊，是真的吗？</h2>
      <div className="section-seotext__row">
        <div className="section-seotext__cell">
          <p>
            原本以为出国留学的生活会很美好，
            很舒适。可以去其他城市旅游，和小伙伴聚会，标准的夜生活。没想到美好的日子不久就被essay打破了。
            <strong>
              天天有一堆作业，还要上课，又想多与外国人交流锻炼口语，怎么办呢？
            </strong>
            CustomWritings.com代写文章的网站帮助你解决困难。
          </p>
          <p>
            CustomWritings.com提供不同类型的网上代写服务，包括essay代写、商业计划书代写、项目计划书代写、代写材料、代写演讲稿、代写工作总结、代写作业、代写自传、书稿代写、起诉书代写、剧本代写、作文代写、代写新闻稿、软文代写、sci论文润色、sci论文发表、论文润色、论文翻译、论文修改、商务英语论文代写、mba论文代写、数学论文代写、大学论文代写、代写可行性报告、代写专著、课题代写、assignment代写、英文论文润色、英文论文修改、英文论文翻译、代做ppt、书评代写、代发职称论文、学术论文代写、科技论文代写、可行性研究报告代写等。
          </p>
          <p>
            很多同学都怀疑专业代写是否合法，属不属于作弊。答案是取决于学生选择的﻿代写网站。
            <strong>
              在CustomWritings.com网站下单的学生将获得有效的学术帮助，所获得的定制写作服务并不代表作弊。
            </strong>
            就像有些学生花钱请家教补习功课一样，不代表作弊或违法。很多留学生不了解论文的格式或没有思路，所以在网站下单。也有很多学生语法和句子组织能力不是那么强，所以也需要获得学术帮助。甚至很多留学生因为生病了，没有上课无法完成老师提的要求，也没有足够的时间来完成作业，所以找CustomWritings.com提供代写文章，代写作业，代写作文服务。
          </p>
        </div>
        <div className="section-seotext__cell">
          <p>
            CustomWritings.com网站提供的所有文章代写都是原创的。我们明白学生们希望收到符合写作要求抄袭率低的作品。网站也从来不会提供抄袭他人的或现有的论文，也不会将您的原创作品卖给别人。
            <strong>
              CustomWritings.com代写网站的作家团队代写原创文章经验丰富，精通于各个科目，可以帮助留学生代写不同类型的文章。
            </strong>
            所有的作家都很专业，能够根据写作要求按时地完成优秀的作品。关于代写专利多少钱
            、代写自传价格、代写赚钱、商业计划书代写价格、代写一篇sci多少钱、代写作业兼职请进入代写服务价格页面。
          </p>
          <p>
            紧急需要论文代写、软文代写、代写演讲稿、essay代写、代发论文、代写文章、代写书稿、代写作文、作业代写、申请书留学代写、代写公文、代写爱情小说、代写代发论文、演讲稿代写、代写剧本或其他代写服务吗？
            <strong>
              CustomWritings.com提供高质量的代写服务，绝对是最可靠的代写网站。
            </strong>
          </p>
        </div>
      </div>
    </Section>
  </Layout>
);

export default IndexPage;
