import React from 'react';
import PropTypes from 'prop-types';
import './_Testimonial.scss';

const Testimonial = ({
  id,
  comment,
  topicTitle,
  discipline,
  date,
}) => (
  <div className="testimonials__item">
    <div className="testimonials__item__date"><span>{`ID ${id}xxx`}</span>{date}</div>
    <div className="testimonials__item__body">{comment}</div>
    <div className="testimonials__item__topic">{topicTitle}</div>
    <div className="testimonials__item__discipline">{discipline}</div>
  </div>
);

Testimonial.propTypes = {
  id: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  topicTitle: PropTypes.string.isRequired,
  discipline: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

export default Testimonial;
