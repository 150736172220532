import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import SampleCard from './SampleCard';

import './_Samples.scss';


const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
  slidesToShow: 4,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 3,
        draggable: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        draggable: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        draggable: true,
      },
    },
  ],
};

const Samples = ({ samples }) => (
  <div className="samples-slider">
    <Slider {...settings}>
      {
        [].slice.call(samples).map(sample => (
          <div key={`${sample.paperFormat}-${sample.sources}-${sample.pdfLink}`}>
            <SampleCard {...sample} />
          </div>
        ))
      }
    </Slider>
  </div>
);

Samples.propTypes = {
  samples: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
};

export default Samples;
