import React from 'react';
import './_index.scss';


const WeDoBlock = () => (
  <div className="wedo-list">
    <div className="wedo-list__item ico_modify">
      根据我们的修改政策，免费修改订单
    </div>
    <div className="wedo-list__item ico_exp_writers ">
      经验丰富，能力强大的作家团队
    </div>
    <div className="wedo-list__item ico_flexible-pricing">
      价格实惠，优惠促销
    </div>
    <div className="wedo-list__item ico_hard_order">
      不放弃任何难度高的订单
    </div>
    <div className="wedo-list__item ico_exp_company">
      10年市场经验
    </div>
    <div className="wedo-list__item ico_short-deadlines">
      连夜赶稿功能
    </div>
    <div className="wedo-list__item ico_native">
      英语为母语的作家（美国、加拿大）
    </div>
    <div className="wedo-list__item ico_complex">
      下单前可以查阅作家的范文
    </div>
  </div>
);

export default WeDoBlock;
