/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';

import './_SampleCard.scss';


const SampleCard = ({
  sampleTitle,
  pdfLink,
  paperTitle,
  academicLevel,
  discipline,
  paperFormat,
}) => (
  <div className="sample">
    <div className="sample__type">{sampleTitle}</div>
    <div className="sample__title" data-label="Paper title">{paperTitle}</div>
    <div className="sample__level" data-label="Academic level">{academicLevel}</div>
    <div className="sample__discipline" data-label="Discipline">{discipline}</div>
    <div className="sample__format" data-label="Paper Format">{paperFormat}</div>
    <a className="btn btn--white" data-sample-id="" href={withPrefix(pdfLink)}>查看案例</a>
  </div>
);

SampleCard.propTypes = {
  sampleTitle: PropTypes.string.isRequired,
  pdfLink: PropTypes.string.isRequired,
  paperTitle: PropTypes.string.isRequired,
  academicLevel: PropTypes.string.isRequired,
  discipline: PropTypes.string.isRequired,
  paperFormat: PropTypes.string.isRequired,
};

export default SampleCard;
