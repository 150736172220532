import React from 'react';
import PropTypes from 'prop-types';

import './_index.scss';

const BannerButtons = ({ children }) => (
  <div className="section-banner__buttons">
    {children}
  </div>
);

BannerButtons.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BannerButtons;
